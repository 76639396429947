import React from 'react'
import Layout from '../components/Layout'

import menuFront from '../img/menuFront.jpg'
import menuBack from '../img/menuBack.jpg'

class MenuPage extends React.Component {

  render() {
    return (
      <Layout>
        <div
          style={{
            backgroundColor: 'black',
            textAlign: 'center'
          }}
        >
          <img
            src={menuFront}
            alt="Justin's Burgers Menu Front"
            style={{
              width: '600px',
              maxWidth: '100%'
            }}
          />
          <br/>
          <br/>
          <br/>
          <img
            src={menuBack}
            alt="Justin's Burgers Menu Back"
            style={{
              width: '600px',
              maxWidth: '100%'
            }}
          />
        </div>
      </Layout>
    )
  }
}

export default MenuPage
